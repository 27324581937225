'use client';

import React from 'react';
import { useTranslations } from 'next-intl';

import { Button, ButtonProps, Checkbox } from '@zealy/design-system';
import { Filter2Line } from '@zealy/icons';

import { ResponsivePopover } from '#components/ResponsivePopover';
import { useEventTracker } from '#context';

import { useQuestboardContext } from '../QuestboardContext';
import { CategoryFilters } from './CategoryFilters';

const FILTERS = ['locked', 'available', 'inCooldown', 'inReview', 'completed'] as const;

export const StatusFilters = (buttonProps: ButtonProps) => {
  const { analytics } = useEventTracker();
  const t = useTranslations('questboard.filters');
  const { filters, toggleFilter } = useQuestboardContext();

  return (
    <ResponsivePopover trigger={<Button onlyIcon leftIcon={<Filter2Line />} {...buttonProps} />}>
      <p className="body-component-md-bold text-secondary py-50 px-100">{t('title')}</p>
      {FILTERS.map(filter => (
        <Checkbox
          key={filter}
          name="filters"
          checked={filters?.includes(filter)}
          onCheckedChange={checked => {
            analytics.track('Toggle Questboard Filter', {
              filter,
              checked,
            });
            toggleFilter(filter);
          }}
        >
          {t(filter)}
        </Checkbox>
      ))}
      <div className="flex flex-col gap-100 md:hidden">
        <p className="body-component-md-bold text-secondary py-50 px-100">{t('by-module')}</p>
        <div className="flex flex-wrap gap-100">
          <CategoryFilters />
        </div>
      </div>
    </ResponsivePopover>
  );
};
