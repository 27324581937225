import React from 'react';
import { cx } from 'class-variance-authority';
import { useTranslations } from 'next-intl';
import { useParams, usePathname, useRouter } from 'next/navigation';

import type { GetCategoryOutput, GetQuestboardOutput } from '@zealy/utils';
import { Button } from '@zealy/design-system';
import { ArrowLeftLine } from '@zealy/icons';

import { QuestCard } from '#components/QuestCard';
import { getQuestStatus } from '#components/QuestCard/components/QuestStatus/QuestStatus.utils';
import { useKeyboardShortcut } from '#hooks/useKeyboardShortcut';
import { cn } from '#utils/utils';

import { useQuestNavigation } from '../Board.hooks';
import { StatusFilters } from '../BoardHeader/StatusFilters';
import { ModuleCard } from './ModuleCard';

export const Module = ({
  category,
  isSubscriptionLimitReached,
  fullHeight,
  className,
}: {
  category: GetQuestboardOutput[number] | GetCategoryOutput;
  isSubscriptionLimitReached?: boolean;
  fullHeight?: boolean;
  className?: string;
}) => {
  const t = useTranslations('common');
  const router = useRouter();
  const { subdomain, questId } = useParams<{ subdomain: string; questId?: string }>();
  const { nextQuest, prevQuest } = useQuestNavigation(questId);
  const pathname = usePathname();
  const baseUrl = `${pathname.substring(0, pathname.indexOf('/questboard'))}/questboard`;

  useKeyboardShortcut(
    fullHeight
      ? {
          ArrowDown: () => {
            if (nextQuest) {
              router.push(`${baseUrl}/${nextQuest.categoryId}/${nextQuest.id}#${nextQuest.id}`);
            }
          },
          ArrowUp: () => {
            if (prevQuest) {
              router.push(`${baseUrl}/${prevQuest.categoryId}/${prevQuest.id}#${prevQuest.id}`);
            }
          },
        }
      : {},
  );

  const openModule = () => {
    const openFirstQuest = window.matchMedia('(min-width: 1024px)').matches;

    const firstQuest = category.quests[0];

    const firstQuestIsOpen = firstQuest && getQuestStatus(firstQuest, false).canClaim;

    const url =
      openFirstQuest && firstQuestIsOpen
        ? `${baseUrl}/${category.id}/${category.quests[0].id}`
        : `${baseUrl}/${category.id}`;

    router.push(url);
  };

  return (
    <ModuleCard
      key={category.id}
      name={category.title}
      onClick={() => openModule()}
      quests={category.totalQuestCount}
      completed={category.claimedQuestCount}
      status={isSubscriptionLimitReached ? 'frozen' : category.status ?? 'open'}
      variant="neutral"
      color={category.color}
      image={category.coverUrl}
      position={category.position}
      description={(category as GetCategoryOutput).description}
      className={cn('w-full min-w-0', className)}
      fullHeight={fullHeight}
      actions={
        fullHeight ? (
          <div className="flex justify-between">
            <Button
              onClick={() => router.push(`/cw/${subdomain}/questboard`)}
              onlyIcon
              leftIcon={<ArrowLeftLine />}
              variant="muted"
              className="backdrop-blur-sm"
              aria-label={t('back')}
            />
            <StatusFilters variant="muted" className="backdrop-blur-sm" />
          </div>
        ) : undefined
      }
    >
      {category.quests.map(quest => (
        <QuestCard
          href={`${baseUrl}/${category.id}/${quest.id}`}
          key={quest.id}
          quest={quest}
          isSubscriptionLimitReached={isSubscriptionLimitReached}
          className={cx(quest.id === questId ? 'ring-quest-card-focus' : '')}
        />
      ))}
    </ModuleCard>
  );
};
